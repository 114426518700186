@import "../../../assets/scss/variables";

.listyourspot {
  padding: 25px 0 40px;

  // border-top: 1px solid #E5E5E5;
  @include below-lg() {
    .custompd {
      padding: 0;
    }
  }
  @include below-md() {
    .custompd {
      padding: 0 12px;
    }
  }

  .maincity[aria-expanded="true"] ~ .autocomplete-dropdown-container {
    border: 1px solid var(--box-tile-border-color);
  }

  .nav-item {
    position: relative;

    &.complete {
      .nav-link {
        .circle {
          background: var(--color-secondary);

          &:after {
            border: 1px solid var(--color-secondary);
            background: var(--color-secondary)
              url(../../../assets/images/check.svg) center center no-repeat;
          }
        }
      }
    }

    &.complete:after {
      z-index: 9;
      border-top: 2px dashed var(--color-secondary);
    }

    &:last-child::after {
      display: none;
    }

    &:after {
      position: absolute;
      content: "";
      left: calc(50% + 20px);
      height: 2px;
      top: 15px;
      border-top: 2px dashed var(--color-default-300);
      right: calc(-50% + 20px);
    }
  }

  .nav-link {
    color: var(--color-default-300);
    padding-left: 0;
    padding-right: 0;

    &.active {
      .circle {
        background: var(--color-secondary);

        &:after {
          border: 1px solid var(--color-secondary);
        }
      }
    }

    .circle {
      background: transparent;
      width: 13px;
      height: 13px;
      margin-bottom: 13px;

      &:after {
        position: absolute;
        content: "";
        width: 17px;
        height: 17px;
        border: 1px solid var(--color-default-300);
        left: -2px;
        top: -2px;
        border-radius: 50%;
      }
    }
  }

  h1 {
    font-size: 35px;
  }

  .nav-tabs .nav-link.disabled,
  .nav-tabs .nav-link:disabled {
    color: var(--color-default-400);
  }

  h3 {
    font-size: 25px;
  }

  //     p{
  //         font-weight: normal;
  //         font-size: 15px;
  //         margin-bottom: 20px;
  //     text-align: left;
  //     color: var(--color-default-400);
  //     // opacity: 0.5;
  // }

  textarea {
    min-height: 103px;
  }

  // .form-floating{margin-bottom: 20px;}
  //     .form-control{height: 40px;  border:1px solid var(--color-default-300);    padding-top: 10px !important;
  //     box-shadow: none; color: var( --color-default-400);}

  //     .form-control:hover, .form-control:focus{border:1px solid var(--color-link) ;}
  //     .form-floating > label{padding: 5px 10px;  color: var( --color-default-400); line-height: 27px; font-weight: 400;}
  //    .form-floating > .form-control-plaintext ~ label, .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  //     opacity: 1;
  //     background: var(--bg-white);
  //     transform: scale(.85) translateY(-0.9rem) translateX(0.5rem);
  //     color: var(--color-link);
  //     padding: 0px 10px;
  //     font-weight: 600;
  //     height: 26px;
  //     }

  .uploadbtn {
    min-width: 136px;
    line-height: 36px;
    @include below-lg() {
      min-width: 104px;
    }
    &:hover {
      color: var(--color-secondary);
    }
  }
  label,
  .flpck {
    cursor: pointer;
  }

  .btn-primary {
    min-width: 70px;
  }

  .backbtn {
    min-width: 72px;
  }

  .px-4 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  @include below-md() {
    .px-4 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    h1 {
      font-size: 27px;
    }

    h3 {
      font-size: 21px;
    }
  }

  .operatehour {
    .weektimepicker {
      border-bottom: 1px solid var(--color-default-200);
    }

    .settime {
      margin-bottom: 20px;

      @include below-md() {
        .form-check.mb-0 {
          margin-bottom: 5px !important;
        }

        & {
          margin-bottom: 10px;
        }
      }

      &:last-child {
        margin-bottom: 14px;
      }

      .form-group {
        margin-right: 20px;

        @include below-md() {
          & {
            margin-right: 10px;
          }
        }
      }

      .form-check-label {
        letter-spacing: 2px;
      }

      .btn-outline-secondary {
        border-color: var(--color-primary);
        color: var(--color-primary);

        &:hover,
        &:focus {
          background-color: var(--color-primary);
          color: var(--bg-white);
        }
      }
    }
  }

  .amenities {
    .ratio-boxTiles {
      --bs-aspect-ratio: 84%;

      @include below-lg() {
        & {
          --bs-aspect-ratio: 100%;
        }
      }

      .boxTilesInner {
        padding: 15px;

        @include below-md() {
          & {
            padding: 7px;
          }
        }

        .boxTileTitle {
          @include below-lg() {
            & {
              line-height: 18px;
            }
          }
        }

        .form-check-input[type="checkbox"] {
          border-radius: 25px;
        }

        .amenitiescheck {
          .form-check-input {
            opacity: 0;

            &:checked {
              background-color: var(--color-secondary);
              border-color: var(--color-secondary);
              opacity: 1;
            }
          }

          .form-check-input:checked ~ .form-check-label {
            border: 1px solid var(--color-secondary);
          }

          .form-check-label {
            border: 1px solid transparent;
          }
        }
      }
    }
  }
  .form-floating {
    label {
      white-space: normal;
    }
  }
}

.inputchips {
  padding-top: 11px;

  .badge {
    background: #f6f6f6;
    padding: 5px 7px;
    line-height: 12px;
    margin-right: 10px;
    margin-bottom: 10px;

    svg {
      margin-left: 9px;
      cursor: pointer;

      path {
        fill: var(--color-default-400);
      }
    }
  }
}

.calendarbox {
  border-radius: 8px;
  margin-top: 24px;
  border: 1px solid rgba(233, 233, 233, 0.44);
  box-shadow: 0px 20px 21px rgba(0, 0, 0, 0.05);

  .btn-outline-secondary {
    border-color: var(--color-primary);
    color: var(--color-primary);

    &:hover,
    &:focus {
      background-color: var(--color-primary);
      color: var(--bg-white);
    }
  }

  .form-group {
    margin-bottom: 20px;
  }

  .rightbox {
    padding: 20px;
    height: 100%;
    border-left: 1px solid #f5f5f5;
    margin-left: -8px;

    .text-end {
      svg {
        color: var(--color-default-300);
      }
    }

    @include below-md() {
      & {
        margin-left: 0;
        padding: 0 15px 0px 15px;
      }
    }
  }
}

.calendarui {
  padding: 14px;

  @include below-md() {
    & {
      padding: 10px 0 0;
    }
  }

  .react-datepicker {
    width: 100%;
    border: none;
    font-family: inherit;
  }

  .react-datepicker__day--outside-month {
    color: var(--color-default-300);
  }

  .react-datepicker__navigation-icon--previous {
    &::before {
      transform: rotate(0000deg);
      right: -7px;
      content: "";
      top: 0;
      width: 0;
      height: 0;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
      border-left: 8px solid transparent;
      border-right: 8px solid var(--color-default-400);
      clear: both;
    }
  }

  .react-datepicker__navigation-icon--next {
    &::before {
      transform: rotate(0000deg);
      left: -7px;
      content: "";
      top: 0;
      width: 0;
      height: 0;
      border-bottom: 8px solid transparent;
      border-top: 8px solid transparent;
      border-left: 8px solid var(--color-default-400);
      border-right: 8px solid transparent;
      clear: both;
    }
  }

  .react-datepicker__navigation {
    top: 10px;
  }
  .react-datepicker__navigation.react-datepicker__navigation--previous:hover
    *::before {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid var(--color-default-400);
  }
  .react-datepicker__navigation.react-datepicker__navigation--next:hover
    *::before {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-left: 8px solid var(--color-default-400);
    border-right: 8px solid transparent;
  }

  .react-datepicker__current-month {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-around;

    &:last-child {
      .react-datepicker__day {
        margin-bottom: 0;
      }
    }

    > div {
      &:last-child {
        &::before {
          display: none;
        }
      }
    }

    > .react-datepicker__day.react-datepicker__day--in-selecting-range {
      &:last-child {
        &:before {
          display: none;
        }
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }

  .react-datepicker__day-name {
    font-weight: 600;
    font-size: 13px;
    height: 34px;
    flex-basis: 0 0 34px;
  }

  .react-datepicker__day {
    font-size: 13px;
    height: 34px;
    line-height: 34px;
    margin: 0;
    width: 34px;
    position: relative;
    border-radius: 50%;
    z-index: 2;

    &:last-child.react-datepicker__day--in-selecting-range {
      &::before {
        content: "";
        background: #000;
        width: 100%;
        position: absolute;
        left: 100%;
        top: 0;
        height: 100%;
        z-index: -1;
      }
    }
  }

  .react-datepicker__header {
    border: none;
    background: transparent;
  }

  .react-datepicker__day--in-range {
    background: #ffeefc;
    color: var(--color-default);
    border-radius: 0;
  }
  .react-datepicker__day--in-range:hover {
    background-color: var(--color-primary);
    border-radius: 50%;
    color: var(--bg-white);
  }

  .react-datepicker__day--in-selecting-range {
    background: #ffeefc;
    color: var(--color-default);
    border-radius: 0;
  }

  .react-datepicker__day--range-end {
    background: var(--color-primary);
    background-color: var(--color-primary) !important;
    border-radius: 50%;
    color: var(--bg-white);
    font-weight: 400;

    &::before {
      display: none;
    }
  }

  .react-datepicker__day:hover {
    border-radius: 50%;
  }
  .react-datepicker__day--today {
    // background: var(--color-secondary);
    // border-radius: 50%;
    // background-color: var(--color-secondary) !important;
    // color: var(--bg-white);
    // font-weight: 400;

    &::before {
      display: none;
    }
  }

  // .react-datepicker__day--keyboard-selected {
  //   background: var(--color-primary);
  //   color: var(--bg-white);
  //   border-radius: 50%;
  //   background-color: var(--color-primary) !important;
  // }
  .react-datepicker__day--keyboard-selected:hover {
    border-radius: 50%;
  }

  .react-datepicker__day--selected {
    background: var(--color-primary);
    color: var(--bg-white);
    border-radius: 50%;
    background-color: var(--color-primary) !important;
  }

  .react-datepicker__day--selected:hover {
    border-radius: 50%;
  }
  .react-datepicker__day.react-datepicker__day--in-selecting-range :hover {
    background-color: var(--color-primary) !important;
  }
  // .react-datepicker__day--selecting-range:hover{
  //     background-color: var(--color-primary) !important;
  // }
  .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range
    ) {
    background-color: var(--color-primary) !important;
    color: var(--bg-white);
    border-radius: 50%;
  }

  .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--today {
    &::before {
      display: none;
    }
  }

  .react-datepicker__day--selected.react-datepicker__day--today {
    &::before {
      content: "";
      background: #000;
      width: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      z-index: -1;
    }
  }

  .react-datepicker__day.react-datepicker__day--in-selecting-range {
    &::before {
      content: "";
      background: #ffeefc;
      width: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      z-index: -1;
    }
  }

  .react-datepicker__day.react-datepicker__day--in-range {
    &::before {
      content: "";
      background: #ffeefc;
      width: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      height: 100%;
      z-index: -1;
    }
  }

  .react-datepicker__day--in-range.react-datepicker__day--today {
    &::before {
      content: "";
      background: #ffeefc;
      width: 100%;
      position: absolute;
      left: 100%;
      display: block;
      top: 0;
      height: 100%;
      z-index: -1;
    }
  }

  .react-datepicker__day--in-selecting-range.react-datepicker__day--today {
    &::before {
      content: "";
      background: #ffeefc;
      width: 100%;
      position: absolute;
      left: 100%;
      display: block;
      top: 0;
      height: 100%;
      z-index: -1;
    }
  }

  // {
  .react-datepicker__day--today {
    &::before {
      display: none;
    }
  }
}

.customtime {
  background: url(../../../assets/images/downarrow.svg) center right 15px
    no-repeat;
  border: 1px solid var(--color-default-300);
}

.selectedatetime {
  padding-bottom: 28px;
  margin-bottom: 40px;

  @include below-md() {
    margin-bottom: 20px;
  }

  .datebox {
    margin-top: 15px;
    border-radius: 5px;
    border-left: 5px solid var(--color-secondary) !important;
    padding: 13px 15px 3px 15px;

    .deleteicon {
      cursor: pointer;
    }

    @include below-md() {
      & {
        position: relative;
        padding: 13px 10px 3px 10px;
      }

      .deleteicon {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 15px;

        svg {
          width: 100%;
        }
      }
    }
  }
}

.publicspotpage {
  .hero_section {
    h1 {
      line-height: 50px;

      @include below-lg() {
        & {
          font-size: 35px;
          word-break: break-word;
        }
      }

      @include below-sm {
        font-size: 27px;
        line-height: 38px;
      }
    }

    min-height: 393px;

    @include below-sm {
      min-height: auto;
    }

    h6 {
      font-size: 18px;
      letter-spacing: 5px;
      line-height: 27px;
    }

    p {
      font-size: 18px;
      margin-bottom: 34px;
    }

    .btn-primary {
      min-width: 114px;
    }

    .form_box {
      display: flex;

      @include below-lg() {
        & {
          padding: 20px;
          display: flex;
        }

        .form-group {
          border-bottom: none;
          padding-left: 0;
        }
      }
    }

    .btn-primary {
      @include below-lg() {
        & {
          margin-top: 0;
        }
      }
    }
  }

  .howdohost {
    padding: 60px 0;

    @include below-lg() {
      & {
        padding: 30px 0;
      }
    }

    h2 {
      font-size: 35px;
    }

    p {
      // color: var(--color-default);
      @include below-lg() {
        &.w-75 {
          width: 100% !important;
        }
      }
    }

    .row {
      margin-bottom: 14px;

      &:last-child {
        .contentbox {
          border-right: none;
          border-left: none;
        }
      }

      &:nth-child(odd) {
        .imgbox {
          padding-left: 36px;
          padding-right: 0;

          @include below-lg() {
            & {
              padding-left: 15px;
              padding-right: 0;
            }
          }

          @include below-md() {
            & {
              padding-bottom: 15px;
              padding-left: 0;
            }
          }
        }

        .contentbox {
          padding-right: 60px;
          padding-left: 0;
          margin-left: 0px;
          margin-right: -24px;
          border-left: none;
          border-right: 2px dashed var(--color-default-400);

          @include below-lg() {
            & {
              padding-right: 50px;
              text-align: left;
            }
          }

          @include below-md() {
            & {
              border-right: none;
            }
          }

          p {
            margin-left: auto;
          }

          h3 {
            font-size: 25px;
            margin-bottom: 13px;
            margin-right: -90px;
            margin-left: 0;
            flex-direction: row-reverse;

            @include below-lg() {
              & {
                margin-right: -70px;
                margin-left: 0px;
              }
            }

            @include below-md() {
              & {
                flex-direction: row;
              }
            }
          }

          .numberbox {
            margin-right: 00;
            margin-left: 28px;

            @include below-md() {
              & {
                margin-left: 0;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }

    .imgbox {
      padding-right: 36px;
      padding-bottom: 30px;

      @include below-lg() {
        & {
          padding-right: 15px;
        }
      }

      @include below-md() {
        & {
          padding-bottom: 15px;
          padding-right: 0;
        }
      }
      img {
        object-fit: cover;
      }
    }

    .contentbox {
      margin-left: -26px;
      padding-left: 60px;
      border-left: 2px dashed var(--color-default-400);
      padding-bottom: 43px;

      @include below-lg() {
        & {
          padding-left: 50px;
        }
      }

      @include below-md() {
        & {
          margin-left: 0;
          border-left: none;

          padding-bottom: 15px;
          padding-left: 0;
          width: 100%;

          p {
            margin-bottom: 0;
          }
        }
      }

      h3 {
        font-size: 25px;
        margin-bottom: 13px;
        margin-left: -90px;

        @include below-lg() {
          & {
            margin-left: -70px;
          }
        }

        @include below-md() {
          & {
            margin-left: 0px;
            font-size: 21px !important;
            width: 100%;
          }
        }
      }
    }

    .numberbox {
      font-size: 18px;
      border: 1px solid var(--color-default-400);
      width: 61px;
      margin-right: 28px;
      height: 61px;
      position: relative;

      @include below-lg() {
        & {
          width: 40px;
          height: 40px;
          min-width: 40px;
        }
      }

      @include below-md() {
        & {
          margin-right: 15px;
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: 10px;
        height: 12px;
        bottom: -13px;
        left: calc(50% - 5px);
        background: var(--bg-white);
      }
    }
  }

  .faq {
    padding: 60px 0;
    box-shadow: 0px 3px 51px rgba(0, 0, 0, 0.07);

    h2 {
      font-size: 35px;
      margin-bottom: 40px;

      @include below-md() {
        & {
          margin-bottom: 20px;
          font-size: 27px;
        }
      }
    }

    .accordion-item {
      border: none;

      &.active {
        background-color: #f6f6f6;
        border-radius: 5px;
        border: 1px solid #e1e1e1;
        border-top: 0 !important;

        .accordion-button {
          border-top: none !important;
          padding-bottom: 25px;
          background: transparent;
        }
      }

      .accordion-body {
        padding: 0 30px 20px 30px;

        @include below-md() {
          & {
            padding: 0 15px 15px 15px;
          }
        }
      }

      .accordion-header {
        border-top: 1px solid #e5e5e5;
        border-bottom: none;
      }
    }

    p {
      font-size: 15px;
    }

    .accordion-flush .accordion-collapse {
      background: #f6f6f6;
    }

    .accordion-button {
      padding: 28px 0;

      @include below-md() {
        & {
          font-size: 16px !important;
        }
      }

      &::after {
        background: url("../../../assets/images/plus.svg") center center
          no-repeat;
      }

      &:not(.collapsed)::after {
        background: url("../../../assets/images/minus.svg") center center
          no-repeat;
      }

      &[aria-expanded="true"]::after {
        background: url("../../../assets/images/minus.svg") center center
          no-repeat;
      }

      &:not(.collapsed) {
        background: #f6f6f6;

        color: var(--color-default);
        border: none;
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .createlisting {
    min-height: 192px;
    background-size: cover;
    background-position: 40px 0;

    h2 {
      font-size: 35px;

      span {
        display: block;
      }

      @include below-md() {
        & {
          font-size: 27px;
        }
      }
    }

    .btn-primary {
      min-width: 129px;

      &:hover {
        background: transparent;
      }
    }

    &::before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: rgba(51, 51, 51, 0.5);
    }
  }
}

// Dropbox
.dropbox.fs-13 {
  border-color: var(--box-tile-border-color);
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 25px 40px;
  .text-link {
    cursor: pointer;
  }
}

.dropboximgbox {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0;

  &.addedImages {
    .closeicon {
      right: 0;
      margin-right: -10px;
    }
    .imageBox {
      border-radius: 5px;
      width: 113px;
      height: 95px;
    }
    > div:not(.closeicon) {
      height: inherit;
      width: 113px;
      margin: 0 26px 16px 0;
      border-radius: 0;
      overflow: inherit;
    }
  }
  > div:not(.closeicon) {
    height: 83px;
    width: 83px;
    margin: 0 26px 16px 0;
    border-radius: 5px;

    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.closeicon {
  width: 20px;
  height: 20px;
  background: var(--color-primary);
  border: 1.5px solid var(--bg-white);
  margin-left: 71px;
  margin-top: -8px;
  cursor: pointer;

  svg {
    width: 8px;
    height: 8px;
  }
}

.form-check-label {
  cursor: pointer;
  .form-check-input {
    cursor: pointer;
  }
}

.customtooltip {
  padding: 8px 12px !important;
  ul {
    li {
      padding-left: 12px;
      &::after {
        position: absolute;
        content: "";
        width: 4px;
        height: 4px;
        background: var(--color-default-400);
        border-radius: 50%;
        left: 0;
        top: 7px;
      }
    }
  }
  &::after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    bottom: -8px;
    left: 50%;
    margin-left: -5px;
    border-top-color: #222;
    border-top-style: solid;
    border-top-width: 10px;
  }
  @include below-sm() {
    left: 0 !important;
  }
}
