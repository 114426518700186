

@import "../../assets/scss/variables";

/* Footer */
.footer{
        background: #333333; padding: 35px 0;
    
        @include below-lg() {
            &{
              padding-bottom: 0;
             
            }
          }
        
          
        h5{font-size: 14px; text-align: left; color: #858585; margin: 0 0 18px 0; font-weight: 600;}

        ul.social_link {
            margin: 0 auto; text-align: left;
            li{margin: 0 0 15px 0;
                a{color: #fff;     font-size: 14px; text-decoration: none;}
                i{ margin-right: 15px; display: inline-block; width:10px; text-align: center;}
            }
            
        }

        ul.foot_link{text-align: left; margin: 0; display: block;
            li{display: block; margin-bottom: 10px;
                a{font-size: 13px ;color: #fff; text-decoration: none;}
            }
        }
        a.foot_logo{max-width: 240px; display: inline-block;}
        p.copyright{
            color: #fff; font-size: 13px; padding: 12px 0; margin-top: 100px; 
            
            @include below-lg() {
                &{
                  margin-top: 5px;
                 
                }
              }
            
            a{color: #fff; text-decoration: none;
            }


}
}



/* Footer */
