@import "../../assets/scss/variables";

/* Header */
.header {
  padding: 0px 30px;
  height: 80px;

  .dropdown {
    .dropdown-toggle {
      span {
        vertical-align: middle;
      }
    }
  }

  .primg {
    width: 38px;
    height: 38px;
  }

  @media print {
    display: none;
  }

  @include below-lg() {
    & {
      padding: 12px 15px;
      height: 70px;
    }
    .navbar-collapse {
      border: 1px solid var(--color-default-200);
      border-radius: 0 0 8px 8px;
      margin-top: 12px;

      @include below-sm() {
        margin-top: 15px;
      }
    }
  }
  .navbar-toggler {
    &:focus {
      box-shadow: none;
    }
    @include below-lg() {
      border: none;
      padding: 0;
    }
  }

  .navbar {
    padding: 00;
  }
  .navbar-brand {
    max-width: 200px;
    margin-right: 0;
    @include below-md() {
      & {
        max-width: 160px;
      }
    }
  }

  .dropdown {
    margin: 17px 0;

    @include below-lg() {
      & {
        margin-top: 0 !important;
        margin-bottom: 0 !important;

        .btn-default {
          font-size: 0 !important;
          padding-right: 5px !important;
        }
      }
    }

    .dropdown-menu {
      border-radius: 6px;
      left: auto;
    }

    .dropdown-item {
      &:hover,
      &:focus {
        color: var(--color-default);
      }
    }

    .dropdown-toggle::after {
      background: url(../../assets//images/downarrow.svg) center center
        no-repeat;
      width: 10px;
      border: none;
      position: absolute;
      right: 0;
      height: 7px;
      top: /* calc(50% - 5px)*/ 50%;
    }
  }

  .navbar-nav {
    padding: 0;
  }
  .nav-item {
    padding: 0;
    margin-left: 20px;
    display: flex;
    align-items: center;
    @include below-xl() {
      margin-left: 8px;
    }

    .badge.rounded-pill {
      margin: 0 0 0 4px;
    }

    @include below-md() {
      margin-left: 0px;
      .btn-primary {
        width: 100%;
      }
    }
    .nav-link {
      font-size: 13px;
      color: var(--color-default);
      padding: 27px 15px;
      border-bottom: 4px solid transparent;
      min-height: 79px;

      @include below-xl() {
        padding: 27px 12px 0;
      }

      @include below-lg() {
        padding: 14px 15px 0 15px;
        border-bottom: none;
        min-height: inherit;
      }

      &.active {
        border-color: var(--color-primary);
      }

      i {
        margin-right: 5px;
      }
    }
    .btn-primary {
      padding: 9px 17px;
      line-height: 22px;
    }
  }
}
.home-header {
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
  .nav-link {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
  }
}

/* Header */
