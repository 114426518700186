.badgesDetail {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 3px 10px #e3e3e3;
    
    
  }

  .badgesDetail .text-secondary {
    min-width: 100px;
    font-weight: 500;
  }

  .badgesDetail .badge {
    padding: 7px 10px;
    font-weight: normal;
    border-radius: 25px;
    background: #f15c60 !important;
  }